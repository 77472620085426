<route>
{
  "meta": {
    "permission": [
      "Invoices.view_receivable"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <i-table
        :title="$tc('receivable', 2)"
        :headers="headers"
        :otherPermission="receivableBtn.payment"
        app="Invoices.receivable"
        api="invoice.receivable"
        @click:edit="changeRoute"
        dontCreate
        dontRemove
      >
        <template v-slot:item.state="{ value }">
          {{ $t(value) }}
        </template>
        <template v-slot:item.date_init="{ value }">
          {{ dateFormat(value) }}
        </template>
        <template v-slot:item.date_end="{ value }">
          {{ dateFormat(value) }}
        </template>
      </i-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      total: 0,
      loading: false,
      receivable: []
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'invoice-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('number_control'),
          value: 'code'
        },
        {
          text: this.$t('start_date'),
          value: 'date_init'
        },
        {
          text: this.$t('end_date'),
          value: 'date_end'
        },
        {
          text: this.receivableBtn.customer ? this.$t('customerCompany') : '',
          value: this.receivableBtn.customer ? 'customer.company' : ''
        },
        {
          text: this.$t('balance'),
          value: 'balance'
        },
        {
          text: this.$t('status'),
          value: 'state'
        },
        {
          text: this.receivableBtn.edit ? this.$t('action') : '',
          value: this.receivableBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    receivableBtn() {
      return {
        customer:
          this.getPermissions([`customer.view_customer`]) || this.isAdmin,
        edit:
          this.getPermissions([`Invoices.change_receivable`]) || this.isAdmin,
        payment: this.getPermissions([`Invoices.view_payments`]) || this.isAdmin
      }
    }
  }
}
</script>
